import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isNilOrEmpty } from '@/utils/validator';

import img1 from './assets/img1.png';
import Text from '../../../components/Text';
import styled from 'styled-components';
import { ReactComponent as ThreeDotsIcon } from '../../../assets/img/three-dots.svg';
import ProjectDetailModal from './ProjectDetailModal';
import Label from './Label';
import FallbackImage from '../../../components/FallbackImage';
import { getDate } from '../../../utils/date';
import { useHistory } from 'react-router-dom';
import paths from '../../../constants/paths';
import useGuards from '../../../hooks/useGuards';

const Wrapper = styled.div`
  border: 1px solid #e1e4e8;
  position: relative;
  width: 384px;
`;
const ThreeDotsIconWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: #ffff00;
  }
`;

const ProjectCard = (props) => {
  const { hasFeature } = useGuards();
  const { env, setEnvironment } = props;
  const { user } = useAuth0();
  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (e) => {
    e.stopPropagation();
    setIsModalVisible(true);
  };

  let defaultPath = paths.visualization;
  if (hasFeature('DASHBOARD') && !isNilOrEmpty(env.dashboardLink)) {
    defaultPath = paths.dashboard;
  } else if (
    hasFeature('DOCUMENTATION') &&
    !isNilOrEmpty(env.documentationLink)
  ) {
    defaultPath = paths.documentation;
  } else if (hasFeature('FILES') && !isNilOrEmpty(env.filesLink)) {
    defaultPath = paths.files;
  }

  const isMigratedEnv = env.name.startsWith('Migrated - ');
  const canSeeMigratedEnv = (
    user.email.includes('-admin@') ||
    user.email.includes('-internal@')
  );

  if (isMigratedEnv && !canSeeMigratedEnv) {
    return <></>;
  }

  return (
    <div className='project-card-container'>
      <Wrapper
        onClick={() => {
          setEnvironment(env.id);
          history.push(defaultPath);
        }}
        className='pointer'
      >
        <Label status={env.status} />
        <FallbackImage
          src={env.imageLink}
          alt={env.name}
          width={384}
          height={216}
          className='mb-16'
          fallback={img1}
        />
        <div className='p-16'>
          <Text className='text20 mb-4'>{env.name}</Text>
          <Text className='text21 mb-16'>{env.description}</Text>
          <div className='flex-b-c'>
            <Text className='text22 mb-4 opacity-40'>
              Last update: {getDate(env.lastUpdateDate)}
            </Text>
            <ThreeDotsIconWrapper onClick={showModal}>
              <ThreeDotsIcon className='pointer' />
            </ThreeDotsIconWrapper>
          </div>
        </div>
      </Wrapper>
      <ProjectDetailModal
        name={env.name}
        description={env.description}
        status={env.status}
        envId={env.id}
        env={env}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      />
    </div>
  );
};

export default ProjectCard;
