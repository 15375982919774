import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { addFilter, removeFilter } from '@carto/react-redux';
import { WrapperWidgetUI, HistogramWidgetUI } from '@atlasai/carto-react-ui';
import { Box } from '@mui/material';
import {
  _FilterTypes as FilterTypes,
  AggregationTypes,
  _hasFeatureFlag,
  _FeatureFlags
} from '@carto/react-core';
import { getHistogram } from '../models';
import { useWidgetFilterValues } from '../hooks/useWidgetFilterValues';
import useWidgetFetch from '../hooks/useWidgetFetch';
import WidgetWithAlert from './utils/WidgetWithAlert';
import useStats from '../hooks/useStats';

const EMPTY_ARRAY = [];

/**
 * Renders a <HistogramWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.title - Title to show in the widget header.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {boolean} props.forcedLoading - force the isLoading mechanism
 * @param  {string} props.column - Name of the data source's column to get the data from.
 * @param  {number=} props.min - Min value of the indicated column.
 * @param  {number=} props.max - Max value of the indicated column.
 * @param  {string} [props.operation] - Operation to apply to the column. Must be one of those defined in `AggregationTypes` object.
 * @param  {number[]=} [props.ticks] - Array of thresholds for the X axis.
 * @param  {number} [props.bins] - Number of bins to calculate the ticks.
 * @param  {Function} [props.xAxisFormatter] - Function to format X axis values.
 * @param  {Function} [props.formatter] - Function to format Y axis values.
 * @param  {'dense' | 'full'} [props.yAxisType='dense'] - Type of Y axis. A dense axis will show only the top value and a full axis will show them all.
 * @param  {boolean} [props.tooltip=true] - Whether to show a tooltip or not.
 * @param  {Function} [props.tooltipFormatter] - Function to return the HTML of the tooltip.
 * @param  {boolean} [props.animation] - Enable/disable widget animations on data updates. Enabled by default.
 * @param  {boolean} [props.filterable] - Enable/disable widget filtering capabilities. Enabled by default.
 * @param  {boolean} [props.global] - Enable/disable the viewport filtering in the data fetching.
 * @param  {Function} [props.onError] - Function to handle error messages from the widget.
 * @param  {object} [props.wrapperProps] - Extra props to pass to [WrapperWidgetUI](https://storybook-react.carto.com/?path=/docs/widgets-wrapperwidgetui--default).
 * @param  {object} [props.noDataAlertProps] - Extra props to pass to [NoDataAlert]().
 * @param  {object} [props.droppingFeaturesAlertProps] - Extra props to pass to [NoDataAlert]() when dropping feature.

 */
function HistogramWidget({
  id,
  title,
  dataSource,
  column,
  operation,
  ticks: _ticks = [],
  min: externalMin,
  max: externalMax,
  xAxisFormatter,
  bins,
  formatter,
  yAxisType,
  tooltip,
  tooltipFormatter,
  animation,
  filterable,
  global,
  onError,
  wrapperProps,
  noDataAlertProps,
  droppingFeaturesAlertProps,

  notes,
  footer,

  binBounds,
  binCounts,
  isExternallyLoading,
  externalFiltersSelector,
  addExternalFilter,
  addExternalFilterExtras,
}) {
  const isExternal = typeof isExternallyLoading === 'boolean';
  const dispatch = useDispatch();
  const externalSelectedValues = useSelector((state) => {
    if (externalFiltersSelector) return externalFiltersSelector(state);
    return null;
  });

  const [binBoundsState , setBinBoundsState] = useState(binBounds);
  const [binCountsState , setBinCountsState] = useState(binCounts);

  const hasExternalMinMax =
    Number.isFinite(externalMin) &&
    externalMin !== Number.MIN_SAFE_INTEGER &&
    Number.isFinite(externalMax) &&
    externalMax !== Number.MAX_SAFE_INTEGER;

  const { stats, warning: _warning } = (
    isExternal
      ? { stats: undefined, warning: undefined }
      : useStats({
        id,
        column,
        dataSource,
        customStats: hasExternalMinMax,
        onError
      })
  );

  const [min, max] = useMemo(() => {
    if (hasExternalMinMax) {
      return [externalMin, externalMax];
    }
    if (stats) {
      return [stats.min, stats.max];
    }
    return [undefined, undefined];
  }, [hasExternalMinMax, stats, externalMin, externalMax]);

  const createExternalTicks = () => {
    const ticks = new Array(binBounds.length);
    for (let i = 0; i < binBounds.length; i++) {
      ticks[i] = binBounds[i][1];
    }
    return ticks;
  }

  const ticks = (
    isExternal
      ? createExternalTicks()
      : useMemo(() => {
        if (_ticks?.length) return _ticks;

        if (bins && hasExternalMinMax) {
          const result = [];
          for (let i = 1; i < bins; i += 1) {
            result.push(min + (max - min) * (i / bins));
          }
          return result;
        }

        return [];
      }, [min, max, _ticks, bins, hasExternalMinMax])
  );

  let data, isLoading, warning, remoteCalculation;

  if (isExternal) {
    data = binCounts;
    isLoading = isExternallyLoading;
  }
  else {
    ({
      data = EMPTY_ARRAY,
      isLoading,
      warning = _warning,
      remoteCalculation
    } = useWidgetFetch(getHistogram, {
      id,
      dataSource,
      params: {
        column,
        operation,
        ticks
      },
      global,
      onError,
      enabled: !!ticks.length,
      attemptRemoteCalculation: _hasFeatureFlag(_FeatureFlags.REMOTE_WIDGETS)
    }));
  }

  const thresholdsFromFilters = (
    isExternal
      ? externalSelectedValues
      : useWidgetFilterValues({
        dataSource,
        id,
        column,
        type: FilterTypes.BETWEEN
      })
  );

  const selectedBars = useMemo(() => {
    return (thresholdsFromFilters || EMPTY_ARRAY)
      .map(([from, to]) => {
        if (typeof from === 'undefined' || from === null) {
          return 0;
        } else if (typeof to === 'undefined' || to === null) {
          return ticks.length;
        } else {
          const idx = ticks.indexOf(from);
          return idx !== -1 ? idx + 1 : 0;
        }
      })
      .filter((v) => v !== null);
  }, [thresholdsFromFilters, ticks]);

  const handleExternalSelectedBarsChange = useCallback(
    (selectedBars) => {
      if (!isExternal) return;
      if (!addExternalFilter) return;

      if (selectedBars?.length > 0) {
        const thresholds = selectedBars.map((i) => binBounds[i]);

        dispatch(
          addExternalFilter({
            id,
            type: FilterTypes.BETWEEN,
            values: thresholds,
            enabled: thresholds.length > 0,
            ...(addExternalFilterExtras || {}),
          })
        );
      }
      else {
        dispatch(
          addExternalFilter({
            id,
            values: [],
            enabled: false,
            ...(addExternalFilterExtras || {}),
          })
        );
      }
    },
    [isExternal, id, dispatch, ticks, min, max]
  );

  const handleSelectedBarsChange = useCallback(
    (selectedBars) => {
      if (selectedBars?.length) {
        const thresholds = selectedBars.map((i) => {
          let left = ticks[i - 1] || min;
          let right = ticks.length !== i ? ticks[i] : max;

          return [left, right];
        });

        dispatch(
          addFilter({
            id: dataSource,
            column,
            type: FilterTypes.BETWEEN,
            values: thresholds,
            owner: id
          })
        );
      } else {
        dispatch(
          removeFilter({
            id: dataSource,
            column,
            owner: id
          })
        );
      }
    },
    [column, dataSource, id, dispatch, ticks, min, max]
  );

  useEffect(() => {
    if (!isExternal) return;
    if (binBounds?.length === undefined) return;
    if (binCounts?.length === undefined) return;

    let changed = false;
    if (
      binBoundsState?.length === undefined ||
      binBounds.length !== binBoundsState.length
    ) {
      changed = true;
      setBinBoundsState(binBounds);
    }
    else {
      for (let i = 0; i < binBounds.length; i++) {
        if (
          binBounds[i][0] !== binBoundsState[i][0] ||
          binBounds[i][1] !== binBoundsState[i][1]
        ) {
          changed = true;
          setBinBoundsState(binBounds);
          break;
        }
      }
    }

    if (
      binCountsState?.length === undefined ||
      binCounts.length !== binCountsState.length
    ) {
      changed = true;
      setBinCountsState(binCounts);
    }
    else {
      for (let i = 0; i < binCounts.length; i++) {
        if (binCounts[i] !== binCountsState[i]) {
          changed = true;
          setBinCountsState(binCounts);
          break;
        }
      }
    }

    if (changed) {
      handleExternalSelectedBarsChange([]);
    }
  }, [
    isExternal,
    binBounds,
    binCounts,
    binBoundsState,
    binCountsState,
  ]);

  return (
    <WrapperWidgetUI title={title} notes={notes} footer={footer} {...wrapperProps} isLoading={isLoading}>
      <WidgetWithAlert
        dataSource={dataSource || binCounts}
        warning={warning}
        global={global}
        droppingFeaturesAlertProps={droppingFeaturesAlertProps}
        noDataAlertProps={noDataAlertProps}
        showDroppingFeaturesAlert={!remoteCalculation}
      >
        {(!!data.length || isLoading) && (
          <HistogramWidgetUI
            data={isExternal ? binCounts : data}
            min={min}
            max={max}
            ticks={ticks}
            selectedBars={selectedBars}
            onSelectedBarsChange={
              isExternal
                ? handleExternalSelectedBarsChange
                : handleSelectedBarsChange
            }
            tooltip={tooltip}
            tooltipFormatter={tooltipFormatter}
            xAxisFormatter={xAxisFormatter}
            yAxisFormatter={formatter}
            yAxisType={yAxisType}
            animation={animation}
            filterable={filterable}
            isLoading={isLoading}
          />
        )}
      </WidgetWithAlert>
    </WrapperWidgetUI>
  );
}

HistogramWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dataSource: PropTypes.string,
  column: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  ticks: PropTypes.arrayOf(PropTypes.number),
  bins: PropTypes.number,
  operation: PropTypes.oneOf(Object.values(AggregationTypes)),
  xAxisFormatter: PropTypes.func,
  formatter: PropTypes.func,
  yAxisType: PropTypes.oneOf(['dense', 'full']),
  tooltip: PropTypes.bool,
  tooltipFormatter: PropTypes.func,
  animation: PropTypes.bool,
  filterable: PropTypes.bool,
  global: PropTypes.bool,
  onError: PropTypes.func,
  wrapperProps: PropTypes.object,
  noDataAlertProps: PropTypes.object,
  droppingFeaturesAlertProps: PropTypes.object
};

HistogramWidget.defaultProps = {
  bins: 15,
  ticks: [],
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  operation: AggregationTypes.COUNT,
  yAxisType: 'dense',
  tooltip: true,
  animation: true,
  filterable: true,
  global: false,
  wrapperProps: {},
  noDataAlertProps: {}
};

export default HistogramWidget;
