import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';
import hash from 'object-hash';

import { WebMercatorViewport } from '@deck.gl/core';
import bboxPolygon from '@turf/bbox-polygon';
import booleanIntersects from '@turf/boolean-intersects';
import { memoize } from '@formatjs/fast-memoize';
import { pathOr } from 'ramda';

import {
  computeHashFromTilesAndViewport,
  createWidgetConfig,
  memoizedCreateWidgetConfig,
} from '@/utils/map-utils';
import { isArrayNotEmpty, isNilOrEmpty } from '@/utils/validator';

import WidgetWrapper from '../common/WidgetWrapper';

export const rebuildWidgets = debounce(({
  viewState,
  isWidgetContainerOpen,
  loadedTilesByLayer,
  analyticsConfigs,
  analyticsConfigsHash,
  layerVisibilityStatuses,
  updateWidgets,
  filtersForCustomLayers,
}) => {
  if (
    isNilOrEmpty(viewState) ||
    isWidgetContainerOpen !== true
  ) {
    return;
  }

  // this guarantees we only have our layers, not Carto layers
  const layerIds = Array.from(
    (new Set(
      analyticsConfigs.map(
        (cfg) => cfg?.layerId || false
      ).filter(Boolean)
    ).values())
  );

  let anyMissingTiles = false;
  for (let i = 0; i < layerIds.length; i++) {
    const layerId = layerIds[i];
    const isVisible = layerVisibilityStatuses.get(layerId);
    if (!isVisible) continue;

    const tiles = loadedTilesByLayer.get(layerId) || [];
    // layer is visible, but no tiles available yet
    if (tiles.length < 1) {
      anyMissingTiles = true;
      break;
    }
  }

  // at least one visible layer has missing tiles, let's defer loading
  if (anyMissingTiles) {
    rebuildWidgets({
      viewState,
      isWidgetContainerOpen,
      loadedTilesByLayer,
      analyticsConfigs,
      analyticsConfigsHash,
      layerVisibilityStatuses,
      updateWidgets,
      filtersForCustomLayers,
    });
    return;
  }

  const bounds = bboxPolygon(
    new WebMercatorViewport(viewState).getBounds()
  );

  let widgets = {};
  for (let i = 0; i < layerIds.length; i++) {
    const layerId = layerIds[i];
    const widgetConfigs = analyticsConfigs.filter((q) => q?.layerId === layerId);
    const widgetConfigsHash = hash.sha1(widgetConfigs);
    const isVisible = layerVisibilityStatuses.get(layerId);
    const tileIds = new Set();

    let features = [];
    if (isVisible && isWidgetContainerOpen) {
      const tiles = loadedTilesByLayer.get(layerId) || [];
      for (let i = 0; i < tiles.length; i++) {
        const tileId = `${layerId}-${tiles[i].id}`;
        tileIds.add(tileId);

        const tileFeatures = tiles[i].dataInWGS84 || [];
        const viewportFeatures = []
        for (let j = 0; j < tileFeatures.length; j++) {
          const oneFeature = tileFeatures[j];
          if (booleanIntersects(oneFeature, bounds)) {
            viewportFeatures.push(oneFeature);
          }
        }
        if (viewportFeatures.length > 0) {
          features = features.concat(viewportFeatures);
        }
      }
    }

    const layerFilters = pathOr({}, ['current', layerId], filtersForCustomLayers);
    const moreWidgets = createLayerWidgets({
      widgetConfigs,
      widgetConfigsHash,
      data: features,
      dataHash: computeHashFromTilesAndViewport(tileIds, bounds),
      layerFilters,
    });
    if (!isNilOrEmpty(moreWidgets)) widgets = {...widgets, ...moreWidgets};
  }
  if (!isNilOrEmpty(widgets)) updateWidgets(widgets);
}, 300);

export const createLayerWidgets = ({
  widgetConfigs,
  widgetConfigsHash,
  data,
  dataHash = undefined,
  layerFilters,
}) => {
  const widgets = {};
  for (const rawWidgetConfig of widgetConfigs) {
    const widgetConfig = createWidgetConfig({
      features: data,
      featuresHash: dataHash,
      rawWidgetConfig,
      layerFilters,
      layerWidgetConfigs: widgetConfigs,
      layerWidgetConfigsHash: widgetConfigsHash,
    });
    if (isNilOrEmpty(widgetConfig)) continue;

    const widget = (
      <WidgetWrapper
        key={widgetConfig.id}
        cfg={cloneDeep(widgetConfig)}
        hideWidget={!isArrayNotEmpty(data)}
      />
    );
    if (!!widget) widgets[widgetConfig.id] = widget;
  }
  return widgets;
};

export const memoizedCreateLayerWidgets = memoize(
  createLayerWidgets,
  {
    serializer: (x) => hash.sha1({
      dataHash: x.dataHash,
      layerFilters: x.layerFilters,
      widgetConfigsHash: x.widgetConfigsHash,
    }),
  },
);
