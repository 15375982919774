export const DOUBLE_DASH = '--';

// ignoredColumns used in showData dataexplorer
export const ignoredColumns = [
  'lng',
  'lat',
  'longitude',
  'latitude',
  'X',
  'Y',
  'geojson',
  '_geojson',
  'geometry',
];

export const LAYER_SOURCE = {
  ELLIPSIS_DRIVE: 'ellipsis',
};

// carto map layers
export const MAP_LAYER_TYPE = {
  CARTO_GEOJSON_LAYER: 'GeoJsonLayer',
  CARTO_HEXAGON_LAYER: 'HexagonLayer',
  CARTO_CPUGRID_LAYER: 'CPUGridLayer',
  CARTO_HEATMAP_LAYER: 'HeatmapLayer',
  TILE_LAYER: 'tile_layer', // id from the bucket config,
  MVT_LAYER: 'mvt_layer', // id from the bucket config,
  TEMPLATE_TILE_LAYER: 'template_tile_layer', // id from the bucket config
  TEMPLATE_MVT_LAYER: 'template_mvt_layer', // id from the bucket config

  XYZ_RASTER: 'xyz_raster',
  MVT: 'mvt',
};

// custom map styles
export const CUSTOM_MAP_STYLES = {
  SATELLITE_ROADS_BW: 'satellite-roads-bw',
};

// different types of map style types
export const MAP_STYLE_TYPE = {
  MAPBOX: 'mapbox',
  GOOGLE_MAPS: 'gmaps',
  AMAZON: 'amazon',
};

// default mapbox styles
export const CLASSIC_MAPBOX_MAP_TYPE = {
  SATELLITE: 'satellite',
  SATELLITE_STREETS: 'satellite-streets',
};

// React component that wraps the base library's Map class (Mapbox | Maplibre)
export const MAPBOX_MAPS = {
  MAPBOX: 'mapbox',
  MAPLIBRE: 'maplibre',
};

// geojson layer mapping to rawlayer types prop
export const GEOJSON_LAYER_MAPPING = {
  heatmap: MAP_LAYER_TYPE.CARTO_HEATMAP_LAYER,
  grid: MAP_LAYER_TYPE.CARTO_CPUGRID_LAYER,
  hexagon: MAP_LAYER_TYPE.CARTO_HEXAGON_LAYER,
};

// template tile layer mapping for creating the uri
export const TEMPLATE_TL_URI_TYPE = {
  LAYERS: 'layers',
  STYLES: 'styles',
};

// the number of pop elements to slice
export const MAP_POPUP_INDEX_REMOVE = 3;

// info panel anchor positon for when popup is clicked
export const DRAWER_ANCHOR = 'right';

// info panel anchor positon for when popup is clicked
export const DRAWER_MAX_MIN_WIDTH = '300';

// marker default colors
export const MARKER_COLORS = {
  RED: 'red',
  BLACK: 'black',
};

// mouse events
export const MOUSE_EVENTS = {
  CLICK: 'click',
  HOVER: 'hover',
};

// default header height used for detecting the header height
export const DEFAULT_HEADER_HEIGHT = 57;

export const TEMPLATE_LAYERS = [
  MAP_LAYER_TYPE.TEMPLATE_TILE_LAYER,
  MAP_LAYER_TYPE.TEMPLATE_MVT_LAYER,
];

export const TEMPLATE_LAYER_TYPE = {
  VECTOR: 'vector',
  RASTER: 'raster',
};

// default max zoom level for layers
export const MAX_ZOOM_LEVEL = 21;
// Ellipsis Drive's max zoom level
export const MAX_ELLIPSIS_ZOOM_LEVEL = 16;

// ellipsis endpoint that give layer info
export const ELLIPSIS_LAYER_INFO_URL = `https://api.ellipsis-drive.com/v3/path/`;

export const OPERATIONS_WIDGETS = {
  ADD: 'add',
  REMOVE: 'remove',
};

export const DEBOUNCE_WIDGET_TIME_VALUE = 2000;

export const LAYER_LINE_WIDTH_MULTIPLIER = 30;

export const FLOAT_TOLERANCE = 0.000001;
