import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBasemap, updateLayer } from '@carto/react-redux';
import { fetchMap } from '@deck.gl/carto';
import { pathOr, has } from 'ramda';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import hash from 'object-hash';

// constants
import { DEFAULT_MAP_TYPE, MAP_STYLES } from '@/utils/basemap-config';
import {
  LAYER_SOURCE,
  TEMPLATE_TL_URI_TYPE,
  DEFAULT_HEADER_HEIGHT,
  TEMPLATE_LAYERS,
  MAP_LAYER_TYPE,
  OPERATIONS_WIDGETS,
} from '@/utils/constants';

// store
import {
  setLayerConfigs,
  setDefaultLayersOrder,
  setCustomLegendLayers,
  setCustomBasemapStyles,
  modifyCustomLegendLayersProps,
  setCustomLayersInfo,
} from '../../store/appSlice';

// utils
import { isArrayNotEmpty, isNilOrEmpty } from '@/utils/validator';
import {
  setBaseMapToConfig,
  mapGoogleToDefault,
  setStateCustomLegendLayers,
  setStateCustomBasemap,
  createBaseMapsfromConfig,
  createLayerMetadataUri,
  createLayerDataUri,
  fetchDataFromUrl,
  fetchLayersTimestampsData,
  orderLayerIds,
  getObjectPropNameByProps,
  setStateLayerInfo,
  extractTimestamps,
  mapTimestamps,
  isTimelineLayer,
  isLayerVisible,
  normalizeLayerConfigs,
  assembleRequestHeaders,
} from '@/utils/map-utils';

// components
import GlobalLoading from '../GlobalLoading';
import WidgetsContainer from './common/WidgetsContainer';
import ZoomControl from './common/ZoomControl';
import MapByConfig from './map/MapByConfig';
import LegendWrapper from './common/LegendWrapper';
import Basemap from '@/components/Carto/common/Basemap';
import Timeline from '@/components/Carto/common/Timeline';

// styles
const useStyles = makeStyles((theme) => ({
  mapWrapper: (props) => ({
    position: 'relative',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: `calc(100% - ${props.headerHeight}px)`,

    // Fix Mapbox attribution button not clickable
    '& #deckgl-wrapper': {
      '& #deckgl-overlay': {
        zIndex: 1,
      },
      '& .mapboxgl-marker': {
        zIndex: 2,
      },
      '& #view-default-view > div': {
        zIndex: 'auto !important',
      },
    },
  }),
  zoomControl: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  legend: {
    position: 'absolute',
    bottom: theme.spacing(4),
    left: theme.spacing(4),
    maxWidth: '300px',
  },
  widgetsContainer: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default function MapContainer(props) {
  const cartoMapId = pathOr(null, ['cartoMapId'], props);
  const analytics = pathOr([], ['analytics'], props);
  const analyticsHash = hash.sha1(analytics);
  const extraLayers = pathOr([], ['extraLayers'], props);

  const [headerHeight, setHeight] = useState(0);
  const [mapConfig, setMapConfig] = useState(null);
  const [currentBasemapStyleId, setCurrentBasemapStyleId] = useState();
  const [currentBasemapStyles, setCurrentBasemapsStyles] = useState(
    MAP_STYLES.filter((style) => style.type === DEFAULT_MAP_TYPE),
  );
  // TODO this should be in redux
  const [isWidgetContainerOpen, setIsWidgetContainerOpen] = useState(false);
  const [widgets, setWidgets] = useState({});
  const [projectViewConfigApplied, setProjectViewConfigApplied] =
    useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [layerTimestamps, setLayerTimestamps] = useState([]);
  const [currentTimestamp, setCurrentTimestamp] = useState(null);
  const [statusLayerVisibility, setStatusLayerVisibility] = useState(new Map());

  const dispatch = useDispatch();
  const cartoCredentials = useSelector((state) => state.carto.credentials);
  const projectViewConfig = useSelector((state) => state.app.projectViewConfig);
  const customLegendLayers = useSelector(
    (state) => state.app.customLegendLayers,
  );
  const customBasemapStyles = useSelector(
    (state) => state.app.customBasemapStyles,
  );
  const defaultLayersOrder = useSelector(
    (state) => state.app.defaultLayersOrder,
  );

  const classes = useStyles({
    ...props,
    headerHeight,
  });

  // check state base map
  const stateBaseMap = pathOr(
    undefined,
    ['mapConfig', 'basemap'],
    projectViewConfig,
  );
  // get all state layers
  const layersFromProjectViewConfig = pathOr(
    [],
    ['mapConfig', 'layers'],
    projectViewConfig,
  );
  
  const cartoLayers = mapConfig?.map?.layers || [];

  const updateWidgets = (updates) => {
    setWidgets((oldWidgets) => {
      const newWidgets = {
        ...oldWidgets,
        ...updates,
      };
      return newWidgets;
    });
  };

  // triggers for fetchmap
  const initialTriggersForFetchMap = () =>
    !isNilOrEmpty(projectViewConfig) &&
    !isNilOrEmpty(cartoMapId) &&
    !isNilOrEmpty(cartoCredentials) &&
    has('accessToken', cartoCredentials);

  // set map is loading
  const setIsMapLoading = (value) => {
    if (value === true) {
      setIsLoading(true);
    } else if (isLoading === true) {
      setIsLoading(false);
    }
  };

  // set the current timestamp
  const onTimelineItemSelect = (timelineItem) => {
    const timeStampId = pathOr('', ['timeStampId'], timelineItem);
    const customLayerId = pathOr('', ['customLayerId'], timelineItem);
    let customLayerUri = pathOr('', ['customLayerUri'], timelineItem);
    if (!isNilOrEmpty(timeStampId) && !isNilOrEmpty(customLayerUri)) {
      customLayerUri = customLayerUri.replace(/{timestampId}/, timeStampId);
      dispatch(
        modifyCustomLegendLayersProps({
          layerId: customLayerId,
          propsToBeChanged: [
            {
              propName: 'uri',
              propValue: customLayerUri,
            },
            {
              propName: 'currentTimestampID',
              propValue: timeStampId,
            },
          ],
        }),
      );
    }
  };

  // methods for custom layers from the config
  const handleLegendWrapperLayerChange = (customLayer) => {
    // treat for particular cases like Timeline layers
    const layerSource = pathOr(null, ['layerSource'], customLayer);
    const layerType = pathOr(null, ['layerType'], customLayer);
    const isCartoLayer = pathOr(null, ['isCartoLayer'], customLayer);
    const visible = isLayerVisible(customLayer);
    const customLayerId = pathOr(null, ['id'], customLayer);

    setStatusLayerVisibility(
      (new Map(statusLayerVisibility)).set(customLayerId, visible)
    );

    // treat carto layers
    if (isCartoLayer) {
      dispatch(
        updateLayer({
          id: customLayerId,
          layerAttributes: {
            visible,
          },
        }),
      );
      return;
    }

    // create a tmp copy of customLayer
    let modifiedCustomLayer = {
      ...customLayer,
    };

    const updateTimestampsForHiddenLayer = (customLayer, layerTimestamps) => {
      // create a new array of layerTimestamps based on pathId to avoid duplicates
      const stateLayerTimestamps = layerTimestamps.filter(
        (layerTimestamp) => layerTimestamp?.customLayerId !== customLayer?.id,
      );
      setLayerTimestamps([...stateLayerTimestamps]);
    };
    const updateTimestampsForVisibleLayer = (
      customLayer,
      layerMetadataUri,
      layerTimestamps,
      props,
    ) => {
      fetchDataFromUrl(
        layerMetadataUri,
        assembleRequestHeaders(customLayer),
      ).then((response) => {
        if (!isNilOrEmpty(response)) {
          const timestamps = extractTimestamps(response);

          if (isArrayNotEmpty(timestamps)) {
            const modifiedTimestamps = mapTimestamps(timestamps, props);
            if (isArrayNotEmpty(modifiedTimestamps)) {
              // dispatch the first timestamp
              onTimelineItemSelect(modifiedTimestamps[0]);
              // combine all the layerTimestamps in one single array
              setLayerTimestamps([...layerTimestamps, ...modifiedTimestamps]);
            }
          }
        }
      });
    };

    if (layerSource === LAYER_SOURCE.ELLIPSIS_DRIVE) {
      const layerHasTimeline = isTimelineLayer(customLayer);
      if (!visible) {
        if (layerHasTimeline) {
          updateTimestampsForHiddenLayer(customLayer, layerTimestamps);
        }
        dispatch(setCustomLegendLayers(modifiedCustomLayer));
      } else {
        // set custom layer extra info
        setStateLayerInfo([customLayer], dispatch, setCustomLayersInfo).then(
          () => {
            // URI to get the layer data
            const customLayerUri = createLayerDataUri(customLayer);

            modifiedCustomLayer = {
              ...customLayer,
              uri: customLayerUri,
            };

            if (layerHasTimeline) {
              // URI to get layer info, which contains timestamps
              const layerMetadataUri = createLayerMetadataUri(customLayer);

              if (
                !isNilOrEmpty(layerMetadataUri) &&
                !isNilOrEmpty(customLayerUri)
              ) {
                updateTimestampsForVisibleLayer(
                  customLayer,
                  layerMetadataUri,
                  layerTimestamps,
                  {
                    customLayerId,
                    customLayerUri,
                    layerType,
                  },
                );
                modifiedCustomLayer.currentTimestampID = null;
              }
            }

            dispatch(setCustomLegendLayers(modifiedCustomLayer));
          },
        );
      }
    } else if (TEMPLATE_LAYERS.includes(layerType)) {
      if (!visible) {
        updateTimestampsForHiddenLayer(customLayer, layerTimestamps);
        dispatch(setCustomLegendLayers(modifiedCustomLayer));
      } else {
        // set custom layer extra info
        setStateLayerInfo([customLayer], dispatch, setCustomLayersInfo).then(
          () => {
            // URI to get the layer data
            const customLayerUri = createLayerDataUri(customLayer);

            // URI to get layer info, which contains timestamps
            const layerMetadataUri = createLayerMetadataUri(customLayer);

            if (
              !isNilOrEmpty(layerMetadataUri) &&
              !isNilOrEmpty(customLayerUri)
            ) {
              updateTimestampsForVisibleLayer(
                customLayer,
                layerMetadataUri,
                layerTimestamps,
                {
                  customLayerId,
                  customLayerUri,
                  layerType,
                },
              );

              // if we have the new uri for layers modify the uri prop
              modifiedCustomLayer = {
                ...customLayer,
                uri: customLayerUri,
                currentTimestampID: null,
              };
            }
            dispatch(setCustomLegendLayers(modifiedCustomLayer));
          },
        );
      }
    } else if (layerType === MAP_LAYER_TYPE.MVT_LAYER && visible) {
      // set custom layer extra info
      setStateLayerInfo([customLayer], dispatch, setCustomLayersInfo).then(
        () => {
          // everything is loaded before dispatching the custom legend layers
          dispatch(setCustomLegendLayers(modifiedCustomLayer));
        },
      );
    } else {
      // dispatch the custom legend layers
      dispatch(setCustomLegendLayers(modifiedCustomLayer));
    }
  };

  // basemap style method
  const onBasemapStyleChange = (id, filteredMapStyles) => {
    setCurrentBasemapsStyles(filteredMapStyles);
    setCurrentBasemapStyleId(id);
    dispatch(setBasemap(id));
  };

  useEffect(() => {
    const getMapConfig = async () => {
      const map = await fetchMap({
        cartoMapId: cartoMapId,
        credentials: cartoCredentials,
      });
      const data = map.response;
      delete map.response;

      let mapConfig = {
        data: data,
        map,
      };
      let idOfCurrentBasemapStyle = mapGoogleToDefault(
        mapConfig?.map?.mapStyle?.styleType,
      );

      if (!isNilOrEmpty(stateBaseMap) && !isNilOrEmpty(mapConfig)) {
        idOfCurrentBasemapStyle = mapGoogleToDefault(stateBaseMap);
      }
      mapConfig = setBaseMapToConfig(idOfCurrentBasemapStyle, mapConfig);
      // sets the map config with the projectViewConfig ones

      setMapConfig({
        ...mapConfig,
      });
    };

    if (mapConfig) return;
    if (
      !isNilOrEmpty(cartoMapId) &&
      !isNilOrEmpty(cartoCredentials) &&
      has('accessToken', cartoCredentials)
    ) {
      getMapConfig();
    }

    // set here the height based on the header due to loading time
    const headerRef = pathOr(null, ['headerRef', 'current'], props);
    if (!isNilOrEmpty(headerRef)) {
      const headerRefHeight = pathOr(
        DEFAULT_HEADER_HEIGHT,
        ['height'],
        headerRef.getBoundingClientRect(),
      );
      if (!isNilOrEmpty(headerRefHeight)) {
        setHeight(headerRefHeight);
      }
    }
  }, [initialTriggersForFetchMap()]);

  useEffect(() => {
    if (
      !mapConfig ||
      Object.keys(projectViewConfig).length < 1 ||
      projectViewConfigApplied === projectViewConfig.key
    ) {
      return;
    }

    dispatch(
      setLayerConfigs({
        order: getLayerOrder(projectViewConfig, mapConfig),
      }),
    );

    const defaultLayersOrderFromProps = pathOr(
      [],
      ['defaultLayersOrder'],
      props,
    );
    dispatch(setDefaultLayersOrder(defaultLayersOrderFromProps));

    // set the visibility of the custom layer based on the state layers
    const extraLayersFromProps = pathOr([], ['extraLayers'], props);

    if (isArrayNotEmpty(extraLayersFromProps)) {
      normalizeLayerConfigs(extraLayersFromProps);

      // set state custom legend layers
      setStateCustomLegendLayers(
        extraLayersFromProps,
        layersFromProjectViewConfig,
        dispatch,
        setCustomLegendLayers,
      );

      // detect time line layers
      const fetchVisibleStateLayerById = (id) =>
        layersFromProjectViewConfig.find(
          (stateLayer) => stateLayer?.id === id && isLayerVisible(stateLayer),
        );
      // set the timeline if we have it
      const fetchAllTimestamps = async () => {
        const responseAllTimestamps = await fetchLayersTimestampsData(
          timeLineVisibleLayers,
        );
        if (isArrayNotEmpty(responseAllTimestamps)) {
          // set all timestamps from save-me config
          setLayerTimestamps([...layerTimestamps, ...responseAllTimestamps]);
          const allCurrentTimestampsIds = [];
          if (isArrayNotEmpty(timeLineVisibleLayers)) {
            for (const timeLineVisibleLayer of timeLineVisibleLayers) {
              const currentTimestampID = pathOr(
                [],
                ['currentTimestampID'],
                timeLineVisibleLayer,
              );
              if (!isNilOrEmpty(currentTimestampID)) {
                allCurrentTimestampsIds.push(currentTimestampID);
              }
            }
          }
          if (isArrayNotEmpty(allCurrentTimestampsIds)) {
            for (const currentTimestampsId of allCurrentTimestampsIds) {
              const layer = responseAllTimestamps.find(
                (ts) => ts?.timeStampId === currentTimestampsId,
              );
              if (!isNilOrEmpty(layer)) {
                setCurrentTimestamp(layer);
                onTimelineItemSelect(layer);
              }
            }
          }
        }
      };

      const timeLineVisibleLayers = extraLayersFromProps
        .filter(
          (layerConfig) =>
            isTimelineLayer(layerConfig) && isLayerVisible(layerConfig),
        )
        .map((extraLayerFromProps) => ({
          ...extraLayerFromProps,
          currentTimestampID: fetchVisibleStateLayerById(
            extraLayerFromProps?.id,
          )?.currentTimestampID,
        }));

      if (isArrayNotEmpty(timeLineVisibleLayers)) {
        const idsForTimelineVisibleLayers = [];
        for (const timeLineVisibleLayer of timeLineVisibleLayers) {
          const idOfTimeLineVisibleLayer = pathOr(
            null,
            ['id'],
            timeLineVisibleLayer,
          );
          if (!isNilOrEmpty(idOfTimeLineVisibleLayer)) {
            idsForTimelineVisibleLayers.push(idOfTimeLineVisibleLayer);
          }
        }
        if (isArrayNotEmpty(idsForTimelineVisibleLayers)) {
          setStateLayerInfo(
            idsForTimelineVisibleLayers.map((layerId) => {
              return extraLayers.find(
                (layerConfig) => layerConfig?.id === layerId,
              );
            }),
            dispatch,
            setCustomLayersInfo,
          );
        }
        fetchAllTimestamps();
      }
    }
    // end of set timeline

    // set the custom basemap
    const extraBaseMapsFromProps = pathOr([], ['extraBaseMaps'], props);
    const extraBaseMaps = createBaseMapsfromConfig(extraBaseMapsFromProps);
    setStateCustomBasemap(extraBaseMaps, dispatch, setCustomBasemapStyles);
    setCurrentBasemapsStyles((customBasemaps) => [
      ...customBasemaps,
      ...extraBaseMaps,
    ]);

    setProjectViewConfigApplied(projectViewConfig.key);
  }, [projectViewConfig, mapConfig]);

  // set the basemap
  useEffect(() => {
    const newMapConfig = setBaseMapToConfig(currentBasemapStyleId, mapConfig);
    if (!isNilOrEmpty(newMapConfig)) {
      setMapConfig({
        ...mapConfig,
      });
    }
  }, [currentBasemapStyleId]);

  // reset the current time layer stamp
  useEffect(() => {
    if (!isArrayNotEmpty(layerTimestamps)) {
      setCurrentTimestamp(null);
    }
  }, [layerTimestamps]);

  if (!cartoMapId || !mapConfig || !projectViewConfigApplied) {
    return (
      <>
        <GlobalLoading />
      </>
    );
  }

  return (
    <>
      {isLoading === true ? <GlobalLoading /> : <></>}
      <Grid item className={`${classes.mapWrapper}`}>
        <MapByConfig
          mapConfig={mapConfig}
          statusLayerVisibility={statusLayerVisibility}
          updateWidgets={updateWidgets}
          setIsMapLoading={setIsMapLoading}
          currentBasemapStyles={currentBasemapStyles}
          analytics={analytics}
          analyticsHash={analyticsHash}
          isWidgetContainerOpen={isWidgetContainerOpen}
        />
        <Hidden xsDown>
          <ZoomControl className={classes.zoomControl} showCurrentZoom />
        </Hidden>
        <LegendWrapper
          className={classes.legend}
          legendLayerConfig={customLegendLayers}
          layersOrder={
            // we should only used "saved" order once a user can actually define the order
            //layerConfigs?.order ||
            orderLayerIds({
              cartoLayers,
              customLegendLayers,
              defaultLayersOrder,
            }) || []
          }
          onLegendWrapperLayerChange={handleLegendWrapperLayerChange}
          tooltipLabel='Show layer'
          toggleButtonLabel='Layers'
        />
        <WidgetsContainer
          className={classes.widgetsContainer}
          widgets={Object.values(widgets)}
          startsOpen={isWidgetContainerOpen}
          handleOpen={setIsWidgetContainerOpen}
        />
        <Basemap
          onBasemapStyleChange={onBasemapStyleChange}
          currentBasemapStyleId={
            currentBasemapStyleId ||
            stateBaseMap ||
            mapConfig?.map?.mapStyle?.styleType
          }
          currentBasemapStyles={[DEFAULT_MAP_TYPE]}
          customBasemapStyles={customBasemapStyles}
        />
        {isArrayNotEmpty(layerTimestamps) && (
          <Timeline
            timestamps={layerTimestamps}
            onItemSelected={onTimelineItemSelect}
            timeLineTitle={'Current date: '}
            currentTimestamp={currentTimestamp}
          />
        )}
      </Grid>
    </>
  );
}

const getLayerOrder = (projectViewConfig, mapConfig) => {
  const projectMapConfig = projectViewConfig.mapConfig || {};
  const viewLayersConfig = projectMapConfig.layers || [];

  const layerOrder = new Set(viewLayersConfig.map((cfg) => cfg.id));
  mapConfig.data.keplerMapConfig.config.visState.layers.forEach((layer) =>
    layerOrder.add(layer.id),
  );
  return Array.from(layerOrder);
};
