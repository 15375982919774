(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-redux"), require("@carto/react-redux"), require("@mui/material"), require("@mui/material/styles"), require("@mui/system"), require("@mui/icons-material"), require("@mui/icons-material/Cancel"), require("@mui/icons-material/CheckCircleOutlined"), require("@mui/icons-material/Close"), require("@mui/icons-material/LayersOutlined"), require("@mui/icons-material/VisibilityOutlined"), require("@mui/icons-material/VisibilityOffOutlined"), require("@mui/icons-material/ExpandMore"), require("@mui/icons-material/ExpandLess"), require("@carto/react-core"), require("@mui/icons-material/KeyboardArrowDown"), require("@carto/react-api"), require("@carto/react-workers"), require("@deck.gl/carto/typed"), require("@carto/react-api/"), require("@carto/react-redux/"), require("@carto/react-core/"), require("@nebula.gl/edit-modes"), require("@nebula.gl/layers"), require("@carto/react-basemaps/"), require("@deck.gl/layers/typed"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-redux", "@carto/react-redux", "@mui/material", "@mui/material/styles", "@mui/system", "@mui/icons-material", "@mui/icons-material/Cancel", "@mui/icons-material/CheckCircleOutlined", "@mui/icons-material/Close", "@mui/icons-material/LayersOutlined", "@mui/icons-material/VisibilityOutlined", "@mui/icons-material/VisibilityOffOutlined", "@mui/icons-material/ExpandMore", "@mui/icons-material/ExpandLess", "@carto/react-core", "@mui/icons-material/KeyboardArrowDown", "@carto/react-api", "@carto/react-workers", "@deck.gl/carto/typed", "@carto/react-api/", "@carto/react-redux/", "@carto/react-core/", "@nebula.gl/edit-modes", "@nebula.gl/layers", "@carto/react-basemaps/", "@deck.gl/layers/typed"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactWidgets"] = factory(require("react"), require("react-redux"), require("@carto/react-redux"), require("@mui/material"), require("@mui/material/styles"), require("@mui/system"), require("@mui/icons-material"), require("@mui/icons-material/Cancel"), require("@mui/icons-material/CheckCircleOutlined"), require("@mui/icons-material/Close"), require("@mui/icons-material/LayersOutlined"), require("@mui/icons-material/VisibilityOutlined"), require("@mui/icons-material/VisibilityOffOutlined"), require("@mui/icons-material/ExpandMore"), require("@mui/icons-material/ExpandLess"), require("@carto/react-core"), require("@mui/icons-material/KeyboardArrowDown"), require("@carto/react-api"), require("@carto/react-workers"), require("@deck.gl/carto/typed"), require("@carto/react-api/"), require("@carto/react-redux/"), require("@carto/react-core/"), require("@nebula.gl/edit-modes"), require("@nebula.gl/layers"), require("@carto/react-basemaps/"), require("@deck.gl/layers/typed"));
	else
		root["cartoReactWidgets"] = factory(root["react"], root["react-redux"], root["@carto/react-redux"], root["@mui/material"], root["@mui/material/styles"], root["@mui/system"], root["@mui/icons-material"], root["@mui/icons-material/Cancel"], root["@mui/icons-material/CheckCircleOutlined"], root["@mui/icons-material/Close"], root["@mui/icons-material/LayersOutlined"], root["@mui/icons-material/VisibilityOutlined"], root["@mui/icons-material/VisibilityOffOutlined"], root["@mui/icons-material/ExpandMore"], root["@mui/icons-material/ExpandLess"], root["@carto/react-core"], root["@mui/icons-material/KeyboardArrowDown"], root["@carto/react-api"], root["@carto/react-workers"], root["@deck.gl/carto/typed"], root["@carto/react-api/"], root["@carto/react-redux/"], root["@carto/react-core/"], root["@nebula.gl/edit-modes"], root["@nebula.gl/layers"], root["@carto/react-basemaps/"], root["@deck.gl/layers/typed"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__2148__, __WEBPACK_EXTERNAL_MODULE__7420__, __WEBPACK_EXTERNAL_MODULE__4823__, __WEBPACK_EXTERNAL_MODULE__586__, __WEBPACK_EXTERNAL_MODULE__8247__, __WEBPACK_EXTERNAL_MODULE__8410__, __WEBPACK_EXTERNAL_MODULE__7905__, __WEBPACK_EXTERNAL_MODULE__1191__, __WEBPACK_EXTERNAL_MODULE__3209__, __WEBPACK_EXTERNAL_MODULE__2375__, __WEBPACK_EXTERNAL_MODULE__2529__, __WEBPACK_EXTERNAL_MODULE__3250__, __WEBPACK_EXTERNAL_MODULE__1510__, __WEBPACK_EXTERNAL_MODULE__6758__, __WEBPACK_EXTERNAL_MODULE__857__, __WEBPACK_EXTERNAL_MODULE__7503__, __WEBPACK_EXTERNAL_MODULE__542__, __WEBPACK_EXTERNAL_MODULE__8225__, __WEBPACK_EXTERNAL_MODULE__6869__, __WEBPACK_EXTERNAL_MODULE__9907__, __WEBPACK_EXTERNAL_MODULE__4281__, __WEBPACK_EXTERNAL_MODULE__5494__, __WEBPACK_EXTERNAL_MODULE__7952__, __WEBPACK_EXTERNAL_MODULE__7967__, __WEBPACK_EXTERNAL_MODULE__853__, __WEBPACK_EXTERNAL_MODULE__4522__) => {
return 