import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import styled from 'styled-components';
import { createRef, useState } from 'react';

import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';

const WidgetsStyleContainer = styled.div`
  button.MuiButtonBase-root h6.MuiTypography-subtitle1 {
    font-weight: bold;
  }
  .MuiCollapse-wrapper .MuiGrid-container button.MuiButtonBase-root {
    flex: 1;
    padding: 0;
    align-items: center;
    justify-content: flex-start;
    text-transform: capitalize;
  }
`;

const useStyles = makeStyles((theme) => ({
  widgetsContainer: {
    width: '350px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: 4,
  },
  button: {
    flex: '1 1 auto',
    justifyContent: 'space-between',
    padding: theme.spacing(0.75, 1.25, 0.75, 3),
    borderBottom: ({ open }) =>
      open ? `1px solid ${theme.palette.divider}` : 'none',
    cursor: 'pointer',
    '& .MuiTypography-subtitle1': {
      textTransform: 'capitalize',
    },
  },
  wrapperInner: {
    maxHeight: 'max(350px, 75vh)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

const WidgetsContainer = (props) => {
  const { startsOpen, handleOpen } = props;
  const [isOpen, setIsOpen] = useState(startsOpen);
  const classes = useStyles({ isOpen });
  const wrapper = createRef();
  const widgets = [...(props.widgets || [])];
  const title = props.title || 'Analytics';

  const toggleOpen = () => {
    const newState = !isOpen
    setIsOpen(newState);
    if (typeof handleOpen === 'function') handleOpen(newState);
  };

  if (widgets.length < 1) return <></>;

  for (let x = widgets.length - 1; x >= 1; x--) {
    widgets.splice(x, 0, <Divider key={`divider-${x}`} />);
  }

  return (
    <WidgetsStyleContainer>
      <Box className={`${classes.widgetsContainer} ${props.className}`}>
        <Grid container direction='row' alignItems='center'>
          <Button
            className={`${classes.button}`}
            onClick={toggleOpen}
            aria-label={title}
            endIcon={<AnalyticsOutlinedIcon />}
          >
            <Typography variant='subtitle1'>{title}</Typography>
          </Button>
        </Grid>
        <Collapse
          ref={wrapper}
          in={isOpen}
          timeout='auto'
          unmountOnExit
          classes={{
            wrapperInner: classes.wrapperInner,
          }}
        >
          {widgets}
        </Collapse>
      </Box>
    </WidgetsStyleContainer>
  );
};

export default WidgetsContainer;
