import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials } from '@carto/react-redux';
import { API_VERSIONS } from '@deck.gl/carto';
import { pathOr } from 'ramda';
import { useAuth0 } from '@auth0/auth0-react';

//import { AUTH_TOKENS } from '../../constants/config';
import Header from '../../components/Header/Header';
import MapContainer from '../../components/Carto/MapContainer';
import useEnvs from '../../hooks/calls/useEnvs';

const Carto = () => {
  const ref = useRef(null);
  const { user } = useAuth0();

  const currentEnv = useSelector((state) => state.app.currentEnv);
  const { data, isLoading: isEnvListLoading } = useEnvs();
  const envList = pathOr([], ['envs'], data);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEnvListLoading) return;
    const dispatchCredentials = async () => {
      const currentEnvConfig =
        envList.find((env) => env.id === currentEnv) || envList[0];
      await dispatch(
        setCredentials({
          apiVersion: API_VERSIONS.V3,
          apiBaseUrl: 'https://gcp-us-east1.api.carto.com',
          //accessToken: AUTH_TOKENS.CARTO_TOKEN,
          accessToken: currentEnvConfig.cartoMapToken,
        }),
      );
    };
    dispatchCredentials();
  }, [envList, isEnvListLoading]);

  const currentEnvConfig = isEnvListLoading
    ? null
    : envList.find((env) => env.id === currentEnv) || envList[0];
  const defaultLayersOrder = pathOr(
    [],
    ['defaultLayersOrder'],
    currentEnvConfig,
  );
  const extraLayers = pathOr([], ['extraLayers'], currentEnvConfig);
  const extraBaseMaps = pathOr([], ['extraBaseMaps'], currentEnvConfig);
  const analytics = pathOr([], ['analytics'], currentEnvConfig);

  const isMigratedEnv = currentEnvConfig.name.startsWith('Migrated - ');
  const canSeeMigratedEnv = (
    user.email.includes('-admin@') ||
    user.email.includes('-internal@')
  );

  if (isMigratedEnv && !canSeeMigratedEnv) {
    return <></>;
  }

  return (
    <>
      <div ref={ref}>
        <Header />
      </div>

      <MapContainer
        headerRef={ref}
        cartoMapId={currentEnvConfig?.cartoMapId}
        analytics={analytics}
        defaultLayersOrder={defaultLayersOrder}
        extraLayers={extraLayers}
        extraBaseMaps={extraBaseMaps}
      />
    </>
  );
};

export default Carto;
