(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@mui/material"), require("react"), require("@mui/material/styles"), require("@mui/system"), require("@mui/icons-material"), require("@mui/icons-material/Cancel"), require("@mui/icons-material/CheckCircleOutlined"), require("react-intl"), require("@formatjs/intl-localematcher"), require("echarts-for-react"), require("@mui/icons-material/Close"), require("@mui/icons-material/LayersOutlined"), require("@mui/icons-material/VisibilityOutlined"), require("@mui/icons-material/VisibilityOffOutlined"), require("@mui/icons-material/ExpandMore"), require("@mui/icons-material/ExpandLess"), require("@carto/react-core"), require("@mui/icons-material/KeyboardArrowDown"));
	else if(typeof define === 'function' && define.amd)
		define(["@mui/material", "react", "@mui/material/styles", "@mui/system", "@mui/icons-material", "@mui/icons-material/Cancel", "@mui/icons-material/CheckCircleOutlined", "react-intl", "@formatjs/intl-localematcher", "echarts-for-react", "@mui/icons-material/Close", "@mui/icons-material/LayersOutlined", "@mui/icons-material/VisibilityOutlined", "@mui/icons-material/VisibilityOffOutlined", "@mui/icons-material/ExpandMore", "@mui/icons-material/ExpandLess", "@carto/react-core", "@mui/icons-material/KeyboardArrowDown"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactUi"] = factory(require("@mui/material"), require("react"), require("@mui/material/styles"), require("@mui/system"), require("@mui/icons-material"), require("@mui/icons-material/Cancel"), require("@mui/icons-material/CheckCircleOutlined"), require("react-intl"), require("@formatjs/intl-localematcher"), require("echarts-for-react"), require("@mui/icons-material/Close"), require("@mui/icons-material/LayersOutlined"), require("@mui/icons-material/VisibilityOutlined"), require("@mui/icons-material/VisibilityOffOutlined"), require("@mui/icons-material/ExpandMore"), require("@mui/icons-material/ExpandLess"), require("@carto/react-core"), require("@mui/icons-material/KeyboardArrowDown"));
	else
		root["cartoReactUi"] = factory(root["@mui/material"], root["react"], root["@mui/material/styles"], root["@mui/system"], root["@mui/icons-material"], root["@mui/icons-material/Cancel"], root["@mui/icons-material/CheckCircleOutlined"], root["react-intl"], root["@formatjs/intl-localematcher"], root["echarts-for-react"], root["@mui/icons-material/Close"], root["@mui/icons-material/LayersOutlined"], root["@mui/icons-material/VisibilityOutlined"], root["@mui/icons-material/VisibilityOffOutlined"], root["@mui/icons-material/ExpandMore"], root["@mui/icons-material/ExpandLess"], root["@carto/react-core"], root["@mui/icons-material/KeyboardArrowDown"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__823__, __WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__586__, __WEBPACK_EXTERNAL_MODULE__247__, __WEBPACK_EXTERNAL_MODULE__410__, __WEBPACK_EXTERNAL_MODULE__905__, __WEBPACK_EXTERNAL_MODULE__191__, __WEBPACK_EXTERNAL_MODULE__993__, __WEBPACK_EXTERNAL_MODULE__527__, __WEBPACK_EXTERNAL_MODULE__306__, __WEBPACK_EXTERNAL_MODULE__209__, __WEBPACK_EXTERNAL_MODULE__375__, __WEBPACK_EXTERNAL_MODULE__529__, __WEBPACK_EXTERNAL_MODULE__250__, __WEBPACK_EXTERNAL_MODULE__510__, __WEBPACK_EXTERNAL_MODULE__758__, __WEBPACK_EXTERNAL_MODULE__857__, __WEBPACK_EXTERNAL_MODULE__503__) => {
return 